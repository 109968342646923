import React from 'react'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import ollie_glass from '../images/people/ollie_glass.jpg'

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>

	<div className="grid">
	    <div className="col-8-12 mobile-col-8-12">
	       <div className="content">
						<h2>Data science, machine learning &amp; data strategy</h2>

            <p><a href="mailto:ollie@ollieglass.com">ollie@ollieglass.com</a> ~ <OutboundLink href="https://twitter.com/ollieglass">@ollieglass</OutboundLink> ~ <OutboundLink href="http://www.linkedin.com/in/ollieglass">LinkedIn</OutboundLink></p>
	       </div>
	    </div>

	    <div className="col-2-12 mobile-col-4-12 push-right">
	       <div className="content">
	           <img style={{ borderRadius: "50%" }} src={ollie_glass} alt="Ollie Glass" />
	       </div>
	    </div>
	</div>

	<p>I create commercial machine learning systems and strategies for companies looking to drive growth with data.</p>

	<p>My work spans data science, engineering, product and strategy. Clients include venture-backed startups funded by Index Ventures, Balderton Capital, BGF and other top investors.</p>

	<p>My work includes:
		<ul className='ul__bullets'>
			<li>Identifying growth opportunities made possible by AI, machine learning and data science.</li>
			<li>Developing early stage ideas into finished data products</li>
			<li>Testing and clarifying ideas with experiments and prototypes</li>
			<li>Creating roadmaps for data products and programs</li>
			<li>Analysing data and communicating findings</li>
			<li>Building, leading and transforming data teams</li>
			<li>Advocating for data science and machine learning within businesses</li>
			<li>Working with stakeholders across all functions and levels</li>
		</ul>
	</p>

	<p>If you're looking for a data scientist, if you have a project in mind or want to explore ideas, feel free to get in touch <a href="mailto:ollie@ollieglass.com">ollie@ollieglass.com</a>. I love talking to startups and about data in general, so don't hesitate to email if there's something I might be able to help out with.</p>

	<p>Please see my <Link to="/services/">services</Link> page for details about how I usually work. If you're not sure where to start try my <Link to="/articles/">articles</Link> or podcast below.</p>

	<h2>About me</h2>

	<p>I discuss product development and data science with <OutboundLink href="https://twitter.com/probablygeorge">George Spencer</OutboundLink> (<OutboundLink href="https://www.rentify.com/">Rentify</OutboundLink>) and <OutboundLink href=
	"https://twitter.com/bengoldsmith90">Ben Goldsmith</OutboundLink> in the <OutboundLink href="http://www.balderton.com/">Balderton Capital</OutboundLink> podcast.</p>

	<p>"You don't need to come to a product oriented data scientist with a solution. You can come to them with an opportunity, or a problem, or a challenge in the business."</p>

	<iframe frameBorder="no" height="166" scrolling="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/232754996&amp;color=ff5500&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false" width="100%" title="Balderton Capital podcast with George Spencer and Ollie Glasss"></iframe>

	<h2>Mailing list</h2>

	<p>I write an occasional newsletter about data strategy, data science and machine learning developments in business and technology. <OutboundLink href='http://eepurl.com/dyFOzj'>You can sign up here</OutboundLink></p>

  </Layout>
)

export default IndexPage